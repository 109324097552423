"use client";

import { Fragment } from "react";
import Tooltip from "@litonarefin/components/Tooltip/Tooltip";
import StyledIcon from "@litonarefin/components/StyledIcon";
import { getIcon } from "@litonarefin/utils/icons";
import Link from "next/link";

function PricingHeader({ price, productId }) {
    return (
        <>
            <div className="jlt-grid jlt-grid-cols-1 md:jlt-grid-cols-2 jlt-gap-8 lg:jlt-gap-0 jlt_pricing_header">
                <div className="jlt-hidden lg:jlt-block"></div>

                {price?.plans?.map((plan, i) => (
                    <Fragment key={i}>
                        <div className="jlt-border jlt-border-[#d9d9d9] jlt-border-b-0">
                            <div className="jlt-bg-[#f5f5f5] jlt-px-4 jlt-py-8 jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
                                <h3 className="jlt-text-3xl">{plan?.plan_details?.title}</h3>
                                <p className="jlt-mt-1 jlt-text-sm">
                                    {plan?.plan_details?.subtitle}
                                </p>
                            </div>
                            <div className="jlt-px-4 jlt-py-8">
                                <h4 className="jlt-leading-[40px] jlt-text-center jlt-font-bold">
                                    <sup className="jlt-text-lg -jlt-top-4">$</sup>
                                    <span>{plan?.plan_details?.price}</span>
                                </h4>
                                <p className="jlt-text-sm jlt-text-center">
                                    {plan?.plan_details?.price_content}
                                </p>
                            </div>

                            {/* For Moble Device */}
                            <div className="lg:jlt-hidden jlt-px-4 jlt-border-b jlt-pb-6">
                                {(price?.feature_title || [])?.map((feature, idx) => (
                                    <div
                                        key={idx}
                                        className="jlt-text-[#475467] jlt-py-3 jlt-flex jlt-items-center jlt-justify-between">
                                        <div className="jlt-flex jlt-items-center jlt-gap-2">
                                            <span>{feature?.title}</span>
                                            {feature?.tooltip ? (
                                                <Tooltip text={feature?.tooltip || ""}>
                                                    <StyledIcon
                                                        color="#fff"
                                                        type="stroke"
                                                        styles={{
                                                            width: "14px",
                                                            height: "14px",
                                                            fill: "#000",
                                                        }}>
                                                        {getIcon("question")}
                                                    </StyledIcon>
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                        <div kay={i}>
                                            {feature?.features?.[i]?.plan_features === "text" ? (
                                                feature?.features?.[i]?.content
                                            ) : !!feature?.features?.[i]?.check ? (
                                                getIcon("check")
                                            ) : (
                                                <StyledIcon color="#F04438">
                                                    {getIcon("close")}
                                                </StyledIcon>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                <div className="jlt-mt-6 jlt-flex jlt-items-center jlt-justify-center">
                                    <Link
                                        href={`/checkout?id=${productId}&license=${
                                            plan?.plan_details?.variation_id
                                        }${
                                            plan?.plan_details?.coupon_code
                                                ? `&coupon=${plan?.plan_details?.coupon_code}`
                                                : ""
                                        }`}
                                        className="jlt_pricing-btn jlt-py-[10px] jlt-px-6 jlt-text-base jlt-font-bold jlt-rounded-full jlt-inline-flex jlt-items-center jlt-justify-center jlt-gap-2"
                                        style={{
                                            color: price?.plans?.[i]?.plan_details?.button_color,
                                            background: plan?.plan_details?.button_background,
                                        }}>
                                        <span>{price?.plans?.[i]?.plan_details?.button_text}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ))}
            </div>
            <style jsx>{`
                @media (min-width: 1024px) {
                    .jlt_pricing_header {
                        grid-template-columns: ${price?.plans?.length
                            ? `1.5fr repeat(${price?.plans?.length}, 1fr)`
                            : ""};
                    }
                }
            `}</style>
        </>
    );
}

export default PricingHeader;
