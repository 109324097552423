"use client";

import Tooltip from "@litonarefin/components/Tooltip/Tooltip";
import StyledIcon from "@litonarefin/components/StyledIcon";
import { getIcon } from "@litonarefin/utils/icons";
import Link from "next/link";

function PricingContent({ price, productId }) {
    return (
        <>
            <table className="jlt-border-collapse jlt-w-full jlt-hidden lg:jlt-grid">
                <tbody>
                    {(price?.feature_title || [])?.map((feature, idx) => (
                        <tr
                            key={idx}
                            className="lg:jlt-border lg:jlt-border-[#d9d9d9] jlt-grid md:jlt-grid-cols-2 jlt_pricing_content jlt-gap-4 lg:jlt-gap-0 odd:jlt-bg-[#FCFCFD] jlt-text-[#475467]">
                            <td className="jlt-hidden jlt-border-r jlt-py-4 jlt-px-8 lg:jlt-flex jlt-items-center jlt-justify-between">
                                <span>{feature?.title}</span>
                                {feature?.tooltip ? (
                                    <Tooltip text={feature?.tooltip || ""}>
                                        <StyledIcon
                                            color="#000"
                                            type="stroke"
                                            styles={{
                                                width: "14px",
                                                height: "14px",
                                            }}>
                                            {getIcon("question")}
                                        </StyledIcon>
                                    </Tooltip>
                                ) : null}
                            </td>
                            {feature?.features?.map((item, i) => (
                                <td
                                    key={i}
                                    className="jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-border-l lg:jlt-border-t-0 lg:jlt-border-b-0 jlt-py-4 jlt-px-8 jlt-flex jlt-items-center jlt-justify-between lg:jlt-justify-center">
                                    {item.plan_features === "text" ? (
                                        item?.content
                                    ) : !!item?.check ? (
                                        getIcon("check")
                                    ) : (
                                        <StyledIcon color="#F04438">{getIcon("close")}</StyledIcon>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}

                    <tr className="jlt-grid jlt-grid-cols-1 md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 jlt_pricing_footer odd:jlt-bg-[#FCFCFD] jlt-text-[#475467]">
                        <td className="jlt-hidden lg:jlt-block"></td>
                        {price?.plans?.map((plan, i) => (
                            <td
                                key={i}
                                className="jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-py-4 jlt-px-8 jlt-flex jlt-items-center jlt-justify-center">
                                <Link
                                    href={`/checkout?id=${productId}&license=${
                                        plan?.plan_details?.variation_id
                                    }${
                                        plan?.plan_details?.coupon_code
                                            ? `&coupon=${plan?.plan_details?.coupon_code}`
                                            : ""
                                    }`}
                                    className="jlt_pricing-btn jlt-py-[10px] jlt-px-6 jlt-text-base jlt-font-bold jlt-rounded-full jlt-inline-flex jlt-items-center jlt-gap-2"
                                    style={{
                                        color: plan?.plan_details?.button_color,
                                        background: plan?.plan_details?.button_background,
                                    }}>
                                    <span>{plan?.plan_details?.button_text}</span>
                                </Link>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
            <style jsx>{`
                @media (min-width: 1024px) {
                    .jlt_pricing_content,
                    .jlt_pricing_footer {
                        grid-template-columns: ${price?.plans?.length
                            ? `1.5fr repeat(${price?.plans?.length}, 1fr)`
                            : ""};
                    }
                }
            `}</style>
        </>
    );
}

export default PricingContent;
