"use client";

import Rating from "react-star-review";

function AvgReview({ reviews }) {
    return (
        <div className="jlt-flex jlt-flex-col jlt-gap-1 jlt-mb-6">
            <Rating
                interactive={false}
                rating={+reviews?.average || 0}
                count={5}
                size={20}
                borderThickness={1}
            />
            <span className="jlt-text-[#FCFCFD] jlt-text-sm jlt-font-medium">
                {reviews?.average}/5 ({reviews?.total_count || 0} Reviews)
            </span>
        </div>
    );
}

export default AvgReview;
